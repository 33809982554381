// import fry from "./futurama-fry.gif"
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={fry} className="App-logo" alt="logo" /> */}
        <h1>Renee Boardway</h1>
      </header>
    </div>
  );
}

export default App;
